import type { StackProps } from "@chakra-ui/react"
import { Stack, Text } from "@chakra-ui/react"
import type { FC, ReactNode } from "react"

type Props = {
  children?: ReactNode
}
const WarningBanner: FC<Props & StackProps> = ({ children, ...stackProps }) => {
  return (
    <Stack
      align={{ base: "left", sm: "center" }}
      bgColor="yellow.50"
      borderColor="yellow.200"
      borderRadius="10px"
      borderWidth="1px"
      direction={{ base: "column", sm: "row" }}
      px={4}
      py={3}
      width="100%"
      {...stackProps}
    >
      {children ? (
        <Text
          color="yellow.500"
          fontWeight="medium"
          textStyle="sm"
          width="100%"
        >
          {children}
        </Text>
      ) : null}
    </Stack>
  )
}

export default WarningBanner
