import { useRef, type FC } from "react"
import { Stack, Skeleton, Flex, Button, Box } from "@chakra-ui/react"
import { useInView } from "framer-motion"
import flatten from "lodash.flatten"

import type {
  GovernanceProposalsQuery,
  GovernanceProposalsQueryVariables,
  Organization,
  Proposal,
  Vote,
} from "query/graphql"
import {
  ProposalsSortBy,
  GovernanceProposalsDocument,
  ProposalStatus,
  useGovernanceProposalsVotesQuery,
} from "query/graphql"
import CardContainer from "common/components/CardContainer"
import { ROUTES } from "common/constants/routes"
import Link from "common/components/Link"
import GovernanceProposalsTable from "governance/components/GovernanceProposalsTable"
import ProposalsListNavTabs from "governance/components/ProposalsListNavTabs"
import { useInfiniteCursorQuery } from "query/hooks/useInfiniteQuery"
import { pluralize } from "common/helpers/string"
import { useAddress } from "web3/hooks/useAddress"

const PAGE_SIZE = 7

type Props = {
  isWhiteLabel: boolean
  organization: Organization
  governorIds: string[]
  displayNavBar?: boolean
  title?: string
}
const OrganizationHomeProposals: FC<Props> = ({
  isWhiteLabel,
  organization,
  governorIds,
  displayNavBar = true,
  title = undefined,
}) => {
  const ref = useRef(null)
  const isVisible = useInView(ref)
  const voterAddress = useAddress()

  const { slug } = organization

  const { data, isLoading } = useInfiniteCursorQuery<
    GovernanceProposalsQuery,
    GovernanceProposalsQueryVariables
  >({
    document: GovernanceProposalsDocument,
    sectionName: "GovernanceProposals",
    nextPagePath: "proposals",
    variables: {
      input: {
        filters: {
          organizationId: organization.id,
        },
        sort: {
          sortBy: ProposalsSortBy.Id,
          isDescending: true,
        },
        page: { limit: PAGE_SIZE },
      },
    },
    enabled: isVisible,
  })

  const pages = data?.pages ?? []
  const allProposals = pages
    .map((page) => page?.proposals?.nodes as Proposal[])
    .filter((proposal) => proposal !== undefined)
  const flattenProposals = flatten(allProposals)
  const proposalIds = flattenProposals.map((proposal) => proposal.id)

  const { data: votesData } = useGovernanceProposalsVotesQuery(
    {
      input: {
        filters: {
          proposalIds,
          voter: voterAddress,
        },
      },
    },
    { enabled: Boolean(voterAddress) && proposalIds && proposalIds.length > 0 },
  )

  if (isLoading) {
    return (
      <Box ref={ref} width="100%">
        <CardContainer title="Proposals">
          <Stack as="section" spacing={2} w="full">
            <Skeletons />
          </Stack>
        </CardContainer>
      </Box>
    )
  }

  const activePendingProposalsCount = flattenProposals.filter(
    (proposal) =>
      proposal.status === ProposalStatus.Active ||
      proposal.status === ProposalStatus.Pending,
  ).length

  // If Arbitrum or at least 5 active proposals, show all proposals
  // Otherwise, show only the first 5 proposals
  const daoPageSize =
    organization.slug === "arbitrum" || activePendingProposalsCount >= 5
      ? PAGE_SIZE
      : 5
  const proposals = flattenProposals.slice(0, daoPageSize)

  const proposalsCount = data?.pages[0]?.proposals?.pageInfo.count ?? 0

  const hasProposals = proposals ? proposals.length > 0 : false

  const remainingProposalsCount = proposalsCount - daoPageSize

  const voterVotesPage = votesData?.votes?.nodes ?? []
  const voterVotes = voterVotesPage
    .filter((voter) => voter !== undefined)
    .map((voter) => voter as Vote)

  return (
    <Box ref={ref}>
      {displayNavBar ? (
        <ProposalsListNavTabs
          borderBottomRadius={0}
          borderTopRadius="md"
          pt={0}
          slug={slug}
        />
      ) : null}

      <CardContainer
        isFullHeightView
        isTableView
        borderTop={0}
        borderTopRadius={0}
        mt={0}
        pb={0}
        title={title}
      >
        <Stack as="section" pt={2} spacing={6} w="full">
          <GovernanceProposalsTable
            displayTableHeader={false}
            isMultiGov={governorIds.length > 1}
            isWhiteLabel={isWhiteLabel}
            organization={organization}
            proposals={proposals}
            voterAddress={voterAddress}
            voterVotes={voterVotes}
          />
        </Stack>

        {hasProposals ? (
          <Flex align="center" justify="center" px={2} py={2} width="100%">
            <Link
              className="no-underline"
              href={ROUTES.governance.proposals.list(organization.slug)}
              width="100%"
            >
              {remainingProposalsCount > 0 ? (
                <Button size="sm" variant="tertiary" width="100%">
                  View +{remainingProposalsCount} active{" "}
                  {pluralize(remainingProposalsCount, "proposal", "proposals")}
                </Button>
              ) : (
                <Button size="sm" variant="tertiary" width="100%">
                  View all
                </Button>
              )}
            </Link>
          </Flex>
        ) : null}
      </CardContainer>
    </Box>
  )
}

const Skeletons = () => (
  <>
    <Skeleton borderRadius="lg" height="30px" />
    {new Array(4).fill(0).map((_, index) => (
      <Skeleton key={index} borderRadius="lg" height="40px" />
    ))}
  </>
)

export default OrganizationHomeProposals
