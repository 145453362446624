import type { ComponentWithAs, IconProps } from "@chakra-ui/react"
import { Box, Flex, Spacer, Stack, Text, Icon, Tooltip } from "@chakra-ui/react"
import { useMemo, type FC } from "react"

import { useTokenQuery, type Organization } from "query/graphql"
import GovernanceAvatar from "governance/components/GovernanceAvatar"
import { useDevice } from "common/hooks/useDevice"
import type { OrganizationAnnouncement } from "organization/helpers/fetcher"
import { isMultiChainTokenIds } from "organization/helpers/organization"
import NetworkTag from "common/components/NetworkTag"
import { getAssetIdParams } from "web3/helpers/assetId"
import CommonTag from "common/components/CommonTag"
import { LinkSimple } from "ui/components/icons/font-awesome/LinkSimple"
import TwitterIcon from "ui/components/icons/TwitterIcon"
import DiscordIcon from "ui/components/icons/DiscordIcon"
import TelegramIcon from "ui/components/icons/TelegramIcon"
import DiscourseIcon from "ui/components/icons/DiscourseIcon"
import Link from "common/components/Link"
import { subString } from "common/helpers/string"
import { getNumberLabelFromBigNumber } from "common/helpers/number"
import { TALLY_DEFAULT_BANNER } from "common/constants/logo"
import { Globe } from "ui/components/icons/font-awesome/Globe"
import { Snapshot } from "ui/components/icons/Snapshot"

type Props = {
  organization: Organization
  organizationAnnouncement?: OrganizationAnnouncement
  isNewLayoutEnabled?: boolean
}
const OrganizationHeader: FC<Props> = ({
  organization,
  organizationAnnouncement,
  isNewLayoutEnabled = false,
}) => {
  const { onLargeDevice, onLittleDevice } = useDevice()

  const imgUrl =
    organizationAnnouncement?.bigBannerImageFile?.url ?? TALLY_DEFAULT_BANNER

  if (isNewLayoutEnabled) {
    return <OrganizationHeaderNew imgUrl={imgUrl} organization={organization} />
  }

  return (
    <Box
      borderRadius="md"
      boxShadow="md"
      mb={4}
      pos="relative"
      py={{ base: 4, md: 6 }}
      style={
        imgUrl
          ? { backgroundImage: `url(${imgUrl})`, backgroundSize: "cover" }
          : {
              backgroundColor: "black",
            }
      }
    >
      <Stack
        alignItems="center"
        direction="row"
        display={onLargeDevice}
        h="100%"
        justifyContent="flex-start"
        px={4}
      >
        {organization.metadata?.icon ? (
          <GovernanceAvatar mr={2} size={20} src={organization.metadata.icon} />
        ) : null}

        <Flex direction="column" width="100%">
          <Text fontSize="3xl" fontWeight="bold" pb={1} textColor="white">
            {organization.name}
          </Text>
          {organization.metadata?.description ? (
            <Text textColor="white">{organization.metadata.description}</Text>
          ) : null}
        </Flex>
      </Stack>

      <Stack
        direction="column"
        display={onLittleDevice}
        h="100%"
        justifyContent="center"
        px={4}
      >
        <Flex direction="row">
          {organization.metadata?.icon ? (
            <GovernanceAvatar
              borderColor="white"
              borderWidth="1px"
              mr={4}
              size={12}
              src={organization.metadata.icon}
            />
          ) : null}

          <Text fontSize="3xl" fontWeight="bold" pb={1} textColor="white">
            {organization.name}
          </Text>
        </Flex>

        {organization.metadata?.description ? (
          <Text textColor="white">{organization.metadata.description}</Text>
        ) : null}
      </Stack>
    </Box>
  )
}

type OrganizationHeaderNewProps = {
  organization: Organization
  imgUrl?: string
}
const OrganizationHeaderNew: FC<OrganizationHeaderNewProps> = ({
  organization,
  imgUrl,
}) => {
  const { onLargeDevice, onLittleDevice } = useDevice()

  const tokenId =
    organization && organization.tokenIds?.length > 0
      ? organization.tokenIds[0]
      : undefined

  const { chainId, isMultiChain } = useMemo(() => {
    // Org has no governors, but it has tokens
    if (organization?.tokenIds && organization.tokenIds.length > 0) {
      const isMultiChain = isMultiChainTokenIds(organization.tokenIds)
      const tokenId = organization.tokenIds[0]
      const { chainId } = getAssetIdParams(tokenId)

      return { chainId, isMultiChain }
    }

    return { chainId: undefined, isMultiChain: false }
  }, [organization])

  const { tokenType } =
    organization && organization.tokenIds?.length > 0
      ? getAssetIdParams(organization.tokenIds[0])
      : { tokenType: undefined }

  const { data: tokenData } = useTokenQuery(
    {
      input: { id: tokenId ?? "" },
    },
    { enabled: Boolean(tokenId) },
  )

  const tokenSupply = tokenData?.token?.supply
    ? getNumberLabelFromBigNumber(
        tokenData?.token?.supply,
        tokenData?.token?.decimals,
      )
    : undefined

  const descriptionMaxLength = { desktop: 160, mobile: 40 }

  return (
    <Box
      borderRadius="md"
      data-qa="banner-dao-page"
      pos="relative"
      style={
        imgUrl
          ? { backgroundImage: `url(${imgUrl})`, backgroundSize: "cover" }
          : {
              backgroundColor: "white",
            }
      }
    >
      <Stack
        bgGradient={{
          base: "linear(to-b, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.56))",
          md: "linear(to-b, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.21), rgba(0, 0, 0, 0.56))",
        }}
        borderRadius="md"
        direction="column"
        display={onLargeDevice}
        h="300px"
        justifyContent="flex-start"
        px={4}
        py={{ base: 4, md: 4 }}
        zIndex="1"
      >
        <Stack direction="row" my={0}>
          {chainId ? (
            <NetworkTag
              chainId={chainId}
              fontWeight="bold"
              isMultiChain={isMultiChain}
            />
          ) : null}
          {tokenType ? (
            <CommonTag
              bgColor="gray.100"
              borderColor="blue.800"
              color="blue.800"
            >
              {tokenType}
            </CommonTag>
          ) : null}

          {tokenSupply ? (
            <CommonTag
              bgColor="gray.100"
              borderColor="blue.800"
              color="blue.800"
            >
              {tokenSupply} Supply
            </CommonTag>
          ) : null}
        </Stack>

        <Spacer />

        <Stack direction="row" my={0}>
          <Stack direction="column" width="60%">
            <Stack align="center" direction="row" spacing={2}>
              {organization.metadata?.icon ? (
                <GovernanceAvatar
                  mr={2}
                  rounded="md"
                  size={12}
                  src={organization.metadata.icon}
                />
              ) : null}
              <Text
                fontSize="32px"
                fontWeight="bold"
                textColor="gray.50"
                textShadow="1px 1px 2px rgba(0, 0, 0, 0.5)"
              >
                {organization.name}
              </Text>
            </Stack>
            {organization.metadata?.description ? (
              organization.metadata?.description.length >
              descriptionMaxLength.desktop ? (
                <Tooltip label={organization.metadata?.description}>
                  <Text
                    textColor="gray.50"
                    textShadow="1px 1px 2px rgba(0, 0, 0, 0.5)"
                  >
                    {subString(
                      organization.metadata.description,
                      descriptionMaxLength.desktop,
                    )}
                  </Text>
                </Tooltip>
              ) : (
                <Text
                  textColor="gray.50"
                  textShadow="1px 1px 2px rgba(0, 0, 0, 0.5)"
                >
                  {subString(
                    organization.metadata.description,
                    descriptionMaxLength.desktop,
                  )}
                </Text>
              )
            ) : null}
          </Stack>
          <Stack align="flex-end" width="40%">
            <Spacer />
            <OrganizationLinks organization={organization} />
          </Stack>
        </Stack>
      </Stack>

      <Stack
        bgGradient={{
          base: "linear(to-b, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.56))",
          md: "linear(to-b, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.21), rgba(0, 0, 0, 0.56))",
        }}
        borderRadius="md"
        direction="column"
        display={onLittleDevice}
        h="100%"
        justifyContent="center"
        px={4}
      >
        <Stack direction="row" mb={4} mt={2}>
          {chainId ? (
            <NetworkTag
              chainId={chainId}
              fontWeight="regular"
              isMultiChain={isMultiChain}
            />
          ) : null}
          {tokenType ? (
            <CommonTag
              bgColor="gray.100"
              borderColor="blue.800"
              color="blue.800"
            >
              {tokenType}
            </CommonTag>
          ) : null}
        </Stack>

        <Flex align="center" direction="row">
          {organization.metadata?.icon ? (
            <GovernanceAvatar
              mr={2}
              rounded="md"
              size={10}
              src={organization.metadata.icon}
            />
          ) : null}

          <Text
            color="gray.50"
            fontSize="26px"
            fontWeight="bold"
            pb={1}
            textShadow="1px 1px 2px rgba(0, 0, 0, 0.5)"
          >
            {organization.name}
          </Text>
        </Flex>

        {organization.metadata?.description ? (
          organization.metadata?.description.length >
          descriptionMaxLength.mobile ? (
            <Tooltip label={organization.metadata?.description}>
              <Text
                pb={2}
                textColor="gray.50"
                textShadow="1px 1px 2px rgba(0, 0, 0, 0.5)"
              >
                {subString(
                  organization.metadata.description,
                  descriptionMaxLength.mobile,
                )}
              </Text>
            </Tooltip>
          ) : (
            <Text
              pb={2}
              textColor="gray.50"
              textShadow="1px 1px 2px rgba(0, 0, 0, 0.5)"
            >
              {subString(
                organization.metadata.description,
                descriptionMaxLength.mobile,
              )}
            </Text>
          )
        ) : null}

        <Flex pb={2}>
          <OrganizationLinks organization={organization} />
        </Flex>
      </Stack>
    </Box>
  )
}

type OrganizationLinksProps = {
  organization: Organization
}
const OrganizationLinks: FC<OrganizationLinksProps> = ({ organization }) => {
  const { metadata } = organization

  const socials = metadata?.socials || {}
  const { discord, others, telegram, twitter, website, discourse } = socials

  const hasLinks =
    discord ||
    telegram ||
    twitter ||
    website ||
    discourse ||
    (others?.length ?? 0) > 0

  const maxOtherLinksToDisplay = 3

  if (!hasLinks) {
    return null
  }

  const getCustomLinkIcon = (value: string) => {
    if (value.startsWith("https://snapshot.org/")) {
      return Snapshot
    }

    if (value.indexOf("Discourse") >= 0) {
      return DiscourseIcon
    }

    return LinkSimple
  }

  return (
    <Stack direction="row">
      {website ? (
        <LinkContainer icon={Globe} label="Website" url={website} />
      ) : null}
      {twitter ? (
        <LinkContainer icon={TwitterIcon} label="Twitter" url={twitter} />
      ) : null}
      {discord ? (
        <LinkContainer icon={DiscordIcon} label="Discord" url={discord} />
      ) : null}
      {telegram ? (
        <LinkContainer icon={TelegramIcon} label="Telegram" url={telegram} />
      ) : null}
      {discourse ? (
        <LinkContainer
          icon={DiscourseIcon}
          label="Governance forum"
          url={discourse}
        />
      ) : null}
      {others
        ? others.slice(0, maxOtherLinksToDisplay).map((otherLink, idx) => {
            if (!otherLink?.label) {
              return null
            }

            const icon = getCustomLinkIcon(otherLink.label)

            return (
              <LinkContainer
                key={`link-${idx}`}
                icon={icon}
                label={otherLink?.label}
                url={otherLink.value}
              />
            )
          })
        : null}
    </Stack>
  )
}

type LinkContainerProps = {
  label: string
  url?: string
  icon: ComponentWithAs<"svg", IconProps>
}
const LinkContainer: FC<LinkContainerProps> = ({ label, url, icon }) => {
  return (
    <Link isExternal href={url}>
      <Tooltip label={label}>
        <Flex
          _hover={{ bgColor: "rgba(74, 85, 104, 0.5)" }}
          align="center"
          bgColor="transparent"
          borderRadius="lg"
          h={{ base: "34px", md: "42px" }}
          justify="center"
          position="relative"
          w={{ base: "34px", md: "42px" }}
        >
          <Box
            border="2px solid white"
            borderRadius="inherit"
            bottom="0"
            left="0"
            opacity="0.5"
            pointerEvents="none"
            position="absolute"
            right="0"
            top="0"
          />
          <Icon as={icon} color="white" h={5} w={5} />
        </Flex>
      </Tooltip>
    </Link>
  )
}

export default OrganizationHeader
