import type { FC } from "react"
import dayjs from "dayjs"
import { HStack, Icon, Text } from "@chakra-ui/react"

import { useAddress } from "web3/hooks/useAddress"
import type { GovernanceClaim } from "governance/hooks/useGovernanceClaimContent"
import { ClaimPeriod } from "governance/hooks/useGovernanceClaimContent"
import type { ProductAnnouncementButton } from "common/components/banners/ProductAnnouncementBanner"
import ProductAnnouncementBanner from "common/components/banners/ProductAnnouncementBanner"
import { ROUTES } from "common/constants/routes"
import { DATE_FORMATS } from "common/constants/date"
import { getWhiteLabelRoute } from "whitelabel/utils/breadcrumb"
import { useIsWhiteLabel } from "whitelabel/hooks/useIsWhiteLabel"
import {
  EligibilityStatus,
  useClaimAirdropEligibleQuery,
  useDelegateStatementQuery,
} from "query/graphql"
import { addressToAccountId } from "web3/helpers/transformers"
import { getWeightLabel, stringToBigNumber } from "common/helpers/bignumber"
import FilledCircleCheck from "ui/components/icons/FilledCircleCheck"
import { getMainnetReference } from "web3/helpers/chainReference"

type Props = {
  governorSlug: string
  governorId: string
  governorClaim?: GovernanceClaim
}
const GovernanceClaimBanner: FC<Props> = ({
  governorSlug,
  governorClaim,
  governorId,
}) => {
  const isWhiteLabel = useIsWhiteLabel()

  const accountAddress = useAddress()

  const addressId = accountAddress
    ? addressToAccountId(accountAddress, getMainnetReference())
    : undefined

  const { data: eligibleData } = useClaimAirdropEligibleQuery(
    {
      input: { id: governorId },
      addressId: addressId ?? "",
    },
    { enabled: Boolean(governorId) && Boolean(addressId) },
  )

  const { data: delegateStatement } = useDelegateStatementQuery(
    {
      input: {
        address: accountAddress as string,
        governorId: governorId,
      },
    },
    { enabled: Boolean(accountAddress) && Boolean(governorId) },
  )

  const hasParticipated = Boolean(accountAddress) && Boolean(delegateStatement)

  if (Boolean(eligibleData)) {
    const token = eligibleData?.governor?.token

    if (token && token.eligibility.status === EligibilityStatus.Claimed) {
      const tokenAmount = token.eligibility?.amount
        ? getWeightLabel(
            stringToBigNumber(token.eligibility.amount),
            token.decimals ?? 18,
          )
        : undefined

      const buttons: ProductAnnouncementButton[] = [
        {
          label: "View",
          url: isWhiteLabel
            ? getWhiteLabelRoute(ROUTES.governance.claim.home(governorSlug))
            : ROUTES.governance.claim.home(governorSlug),
          isPrimary: true,
        },
      ]

      return (
        <ProductAnnouncementBanner buttons={buttons} mt={2}>
          <HStack>
            <Icon as={FilledCircleCheck} color="white" h={5} w={5} />
            <Text fontSize="lg" fontWeight="bold">
              Claimed {tokenAmount} {token.symbol}
            </Text>
          </HStack>
        </ProductAnnouncementBanner>
      )
    }
  }

  if (governorClaim?.claimPeriod === ClaimPeriod.NotStarted) {
    const buttons: ProductAnnouncementButton[] = [
      hasParticipated
        ? {
            label: "View delegate profile",
            url: isWhiteLabel
              ? getWhiteLabelRoute(
                  ROUTES.governance.delegate.profile(
                    governorSlug,
                    accountAddress ?? "",
                  ),
                )
              : ROUTES.governance.delegate.profile(
                  governorSlug,
                  accountAddress ?? "",
                ),
            isPrimary: true,
          }
        : {
            label: "Become a delegate",
            url: isWhiteLabel
              ? getWhiteLabelRoute(
                  ROUTES.governance.delegate.register(governorSlug),
                )
              : ROUTES.governance.delegate.register(governorSlug),
            isPrimary: true,
          },
      {
        label: "Check eligibility",
        url: isWhiteLabel
          ? getWhiteLabelRoute(ROUTES.governance.claim.home(governorSlug))
          : ROUTES.governance.claim.home(governorSlug),
        isPrimary: true,
      },
    ]

    const getDateLabel = () => {
      if (governorClaim?.timeline[1]) {
        const date = governorClaim.timeline[1]

        return dayjs(date).format(DATE_FORMATS.timelineWithTimezone)
      }

      return undefined
    }
    const dateLabel = getDateLabel()

    return (
      <ProductAnnouncementBanner
        buttons={buttons}
        mt={2}
        title={
          dateLabel ? `Claims begin on ${dateLabel}` : "Claims didn't start yet"
        }
      />
    )
  }

  if (governorClaim?.claimPeriod === ClaimPeriod.Active) {
    const buttons: ProductAnnouncementButton[] = [
      {
        label: "Claim now",
        url: isWhiteLabel
          ? getWhiteLabelRoute(ROUTES.governance.claim.home(governorSlug))
          : ROUTES.governance.claim.home(governorSlug),
        isPrimary: true,
      },
    ]

    return (
      <ProductAnnouncementBanner
        buttons={buttons}
        mt={2}
        title="Claim period is open!"
      />
    )
  }

  return null
}

export default GovernanceClaimBanner
