import { useEffect, useState } from "react"
import {
  Stack,
  Link,
  Text,
  Button,
  HStack,
  Box,
  useDisclosure,
} from "@chakra-ui/react"
import truncate from "truncate"
import { BN } from "bn.js"
import { getAccount } from "@solana/spl-token"
import { useConnection, useWallet } from "@solana/wallet-adapter-react"

import CardContainer from "common/components/CardContainer"
import UserAvatar from "common/components/UserAvatar"
import { ROUTES } from "common/constants/routes"
import { pluralize, shortSolAddress } from "common/helpers/string"
import { useDevice } from "common/hooks/useDevice"
import type { Account, Organization } from "query/graphql"
import { isSolanaAddress } from "web3/helpers/address"
import CopyButton from "common/components/CopyButton"
import StakeAccountIntercept from "web3/solana/wormhole-staking/StakeAccountIntercept"
import { useStakeAccount } from "web3/solana/wormhole-staking/StakeAccountProvider"
import { WHTokenBalance } from "web3/solana/wormhole-staking/WHTokenBalance"
import StakingDelegateModal from "web3/solana/wormhole-staking/StakingDelegateModal"
import { WORMHOLE_TOKEN_DECIMALS } from "web3/solana/wormhole-staking/useMultigovStaking"
import { getWeightLabel } from "common/helpers/bignumber"
import MobileCountBox from "common/components/MobileCountBox"

type StakingVotingPowerProps = {
  account: Account
  organization: Organization
  votingPowerContent: Record<string, any>
}

const StakingVotingPower = ({
  account,
  organization,
  votingPowerContent,
}: StakingVotingPowerProps) => {
  const { publicKey } = useWallet()
  const { connection } = useConnection()
  const { onLargeDevice, onLittleDevice } = useDevice()
  const { stakeAccountCustodyAddress } = useStakeAccount()
  const stakeAccountDisclosure = useDisclosure()
  const stakingDelegateDisclosure = useDisclosure()

  const [custodyBalance, setCustodyBalance] = useState("")

  const displayName = Boolean(account.name) ? account.name : account.address

  useEffect(() => {
    async function getCustodyDetails() {
      if (!stakeAccountCustodyAddress) {
        return
      }

      try {
        const custody = await getAccount(connection, stakeAccountCustodyAddress)

        const wh = new WHTokenBalance(new BN(custody.amount.toString()))

        setCustodyBalance(wh.toString())
      } catch {
        setCustodyBalance("")
      }
    }

    getCustodyDetails()
  }, [stakeAccountCustodyAddress, connection])

  return (
    <>
      <CardContainer display={onLargeDevice} title="My voting power">
        {account ? (
          <Stack spacing={3}>
            <HStack>
              <Link
                href={ROUTES.governance.delegate.profile(
                  organization.slug,
                  account.address,
                )}
              >
                <UserAvatar
                  address={account.address}
                  shouldShowBadge={false}
                  size="md"
                  src={account?.picture}
                />
              </Link>
              <Stack spacing={0}>
                <Link
                  href={ROUTES.governance.delegate.profile(
                    organization.slug,
                    account.address,
                  )}
                >
                  <Text color="gray.800" fontSize="md" fontWeight="bold">
                    {isSolanaAddress(displayName)
                      ? shortSolAddress(displayName)
                      : truncate(displayName, 20)}
                  </Text>
                </Link>
                <HStack>
                  <Text color="gray.600" fontSize="sm" fontWeight="medium">
                    {shortSolAddress(account.address)}
                  </Text>
                  <CopyButton
                    size={8}
                    tooltipLabel="address"
                    value={account.address}
                  />
                </HStack>
              </Stack>
            </HStack>
            <HStack>
              <Text color="gray.600" fontSize="sm" fontWeight="regular">
                Total voting power:{" "}
                <Text as="span" fontWeight="semibold">
                  {votingPowerContent?.votes
                    ? `${getWeightLabel(
                        votingPowerContent?.votes,
                        WORMHOLE_TOKEN_DECIMALS,
                      )} W`
                    : "-"}
                </Text>
              </Text>
            </HStack>
          </Stack>
        ) : null}
        <Stack pt={4} spacing={2}>
          <Button
            fontSize="md"
            variant="primary"
            width="100%"
            onClick={
              !custodyBalance
                ? stakeAccountDisclosure.onOpen
                : stakingDelegateDisclosure.onOpen
            }
          >
            Delegate
          </Button>
          {account ? (
            <Link
              href={ROUTES.governance.myVotingPower.index(organization.slug)}
            >
              <Button variant="secondary" width="100%">
                View details
              </Button>
            </Link>
          ) : null}
        </Stack>
      </CardContainer>
      <Box display={onLittleDevice}>
        <MobileCountBox
          footer={
            votingPowerContent?.delegatorsCount
              ? `${votingPowerContent.delegatorsCount} incoming ${pluralize(
                  votingPowerContent.delegatorsCount,
                  "delegation",
                  "delegations",
                )}`
              : "Incoming delegations: -"
          }
          h="110px"
          title="My voting power"
          url={
            publicKey
              ? ROUTES.governance.delegate.profile(
                  organization.slug,
                  publicKey.toBase58(),
                )
              : undefined
          }
        >
          <Text maxWidth="100%">
            {votingPowerContent?.votes
              ? `${getWeightLabel(
                  votingPowerContent?.votes,
                  WORMHOLE_TOKEN_DECIMALS,
                )} W`
              : "-"}
          </Text>
        </MobileCountBox>
      </Box>
      <StakeAccountIntercept
        isOpen={stakeAccountDisclosure.isOpen}
        onClose={stakeAccountDisclosure.onClose}
      />
      <StakingDelegateModal
        isOpen={stakingDelegateDisclosure.isOpen}
        onClose={stakingDelegateDisclosure.onClose}
      />
    </>
  )
}

export default StakingVotingPower
