import { Box, Skeleton, Stack } from "@chakra-ui/react"

import CardContainer from "common/components/CardContainer"
import { ProposalIdentity } from "common/components/columns/ProposalIdentity"
import { ROUTES } from "common/constants/routes"
import { useDevice } from "common/hooks/useDevice"
import type {
  Maybe,
  Organization,
  OrganizationMetadata,
  Proposal,
  ProposalMetadata,
} from "query/graphql"
import Table from "ui/components/Table"

type ProposalsTableProps = {
  isLoading: boolean
  organization: Pick<Organization, "slug"> & {
    metadata?: Maybe<Pick<OrganizationMetadata, "icon">>
  }
  proposals?: (Pick<Proposal, "id" | "status" | "createdAt"> & {
    metadata: Pick<ProposalMetadata, "description" | "title">
  })[]
}

const ProposalsTable = ({
  isLoading,
  organization,
  proposals,
}: ProposalsTableProps) => {
  const { onLargeDevice } = useDevice()

  if (isLoading) {
    return (
      <Box width="100%">
        <CardContainer title="Proposals">
          <Stack as="section" spacing={2} w="full">
            <>
              <Skeleton borderRadius="lg" height="30px" />
              {new Array(4).fill(0).map((_, index) => (
                <Skeleton key={index} borderRadius="lg" height="40px" />
              ))}
            </>
          </Stack>
        </CardContainer>
      </Box>
    )
  }

  return (
    <Box>
      <CardContainer
        isFullHeightView
        isTableView
        borderTop={0}
        borderTopRadius={0}
        mt={0}
        pb={0}
      >
        <Stack as="section" pt={2} spacing={6} w="full">
          <Table
            columns={[
              {
                title: "Proposal",
                isNumeric: false,
                cells: (proposals ?? []).map((proposal) => {
                  return (
                    <ProposalIdentity
                      key={proposal.id}
                      isSignalProposal
                      decimals={18}
                      href={ROUTES.governance.signal.id(
                        organization.slug,
                        proposal.id,
                      )}
                      organization={organization}
                      proposal={proposal}
                      quorum="0"
                    />
                  )
                }),
              },
            ]}
            display={onLargeDevice}
            displayBorderTop={false}
            emptyMessage="No proposals created yet"
            id="proposals"
          />
        </Stack>
      </CardContainer>
    </Box>
  )
}

export default ProposalsTable
