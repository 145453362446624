import type { StackProps } from "@chakra-ui/react"
import { Stack, Text } from "@chakra-ui/react"
import type { FC } from "react"

import WarningBanner from "common/components/banners/WarningBanner"

const StakerBetaAnnouncementBanner: FC<StackProps> = ({ ...stackProps }) => {
  return (
    <WarningBanner
      bgColor="rgb(114, 91, 255)"
      borderColor="rgb(114, 91, 255)"
      borderRadius="6px"
      mb={{ base: 2, md: 0 }}
      py={4}
      {...stackProps}
    >
      <Stack spacing={2}>
        <Text color="white" fontSize="md">
          Welcome to the ARB Staking Closed Beta. Please note rewards earned via
          staking in this beta have no monetary value.
        </Text>
        <Text color="white" fontSize="md">
          Need help? Reach out to{" "}
          <a href="mailto:hello@tally.xyz" rel="noreferrer" target="_blank">
            hello@tally.xyz
          </a>
        </Text>
      </Stack>
    </WarningBanner>
  )
}

export default StakerBetaAnnouncementBanner
