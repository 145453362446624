import type { FC } from "react"
import { Icon, Stack, Text } from "@chakra-ui/react"
import dayjs from "dayjs"

import {
  ElectionStatus,
  RoundStatus,
  useGovernanceCouncilBannerQuery,
  type Organization,
} from "query/graphql"
import { useOrganization } from "organization/providers/OrganizationProvider"
import { getAssetIdParams } from "web3/helpers/assetId"
import { isProductionEnv } from "common/constants/env"
import { FeatureFlag } from "governance/context"
import { ROUTES } from "common/constants/routes"
import {
  getDateFromNow,
  getDifferenceTimeFromNow,
  getTimestampFormat,
} from "common/helpers/date"
import type { ChainId } from "web3/constants/chains"
import chains from "web3/constants/chains"
import Link from "common/components/Link"
import { ArrowRight } from "ui/components/icons/font-awesome/ArrowRight"
import { SecurityCouncil } from "ui/components/icons/SecurityCouncil"
import { useDevice } from "common/hooks/useDevice"

const ELECTION_COMPLETED_MAX_DAYS = 14

type Props = {
  organization: Organization
}
const OrganizationSecurityCouncil: FC<Props> = ({ organization }) => {
  const { onLargeDevice, onLittleDevice } = useDevice()
  const { isFeatureFlagOn } = useOrganization()

  const tokenId = organization.tokenIds?.[0]

  const { data, isLoading } = useGovernanceCouncilBannerQuery(
    {
      tokenId: isProductionEnv
        ? tokenId
        : "eip155:421614/erc20:0x43bcd3a8da1b3637dd2c95e57108dc9382591472",
    },
    { enabled: isFeatureFlagOn(FeatureFlag.Council) },
  )

  if (isLoading) {
    return null
  }

  const council = data?.councils?.[0]

  if (!council) {
    return null
  }

  const election = council.elections?.[0] ?? undefined

  if (!election) {
    return null
  }

  const isRegistrationLive =
    election.nominationRound.status === RoundStatus.Pending

  const getBannerDetails = () => {
    if (election.status === ElectionStatus.Nomination) {
      const dateFromNow = election.nominationRound?.end.ts
        ? getDateFromNow(election.nominationRound?.end.ts)
        : undefined

      const registrationDaysRemaining = election.nominationRound?.start.ts
        ? getDateFromNow(election.nominationRound?.start.ts)
        : undefined

      return {
        description: dateFromNow
          ? isRegistrationLive
            ? `${registrationDaysRemaining} remaining for registration`
            : `${dateFromNow} remaining in Nominee Selection`
          : undefined,
        button: isRegistrationLive
          ? {
              label: "Register",
              url: ROUTES.governance.council.election.index(
                organization.slug,
                council.slug,
                election.number,
              ),
            }
          : {
              label: "Vote",
              url: ROUTES.governance.council.election.round1.index(
                organization.slug,
                council.slug,
                election.number,
              ),
            },
      }
    }

    if (election.status === ElectionStatus.Grace) {
      const dateFromNow = election.nominationRound.endNomineeVotingPeriod.ts
        ? getDateFromNow(election.nominationRound.endNomineeVotingPeriod.ts)
        : undefined

      return {
        description: dateFromNow
          ? `Member Election starts in ${dateFromNow}`
          : `Member Election starts soon`,
        button: {
          label: "See results",
          url: ROUTES.governance.council.election.round1.index(
            organization.slug,
            council.slug,
            election.number,
          ),
        },
      }
    }

    if (election.status === ElectionStatus.Member && tokenId) {
      const { chainId } = getAssetIdParams(tokenId)

      const _chainId = (chains[chainId as ChainId].layer1Id ??
        chainId) as ChainId
      const blockTime = chains[_chainId].blockTime

      const decayPeriodStartDate = dayjs(election.memberRound?.start.ts).add(
        Number(election.memberRound?.fullWeightDuration) * blockTime,
        "second",
      )

      const isDecayPeriodActive = dayjs().isAfter(decayPeriodStartDate)

      return {
        description: isDecayPeriodActive
          ? "Voting decay active! Your voting weight will decrease the longer you wait"
          : `Vote by ${getTimestampFormat(
              decayPeriodStartDate.toISOString(),
              "MM/DD",
            )} to avoid any voting weight decay`,
        button: {
          label: "Vote",
          url: ROUTES.governance.council.election.round2.index(
            organization.slug,
            council.slug,
            election.number,
          ),
        },
      }
    }

    if (election.status === ElectionStatus.Complete) {
      const daysDifference = election.memberRound?.end.ts
        ? getDifferenceTimeFromNow(election.memberRound.end.ts, "days")
        : undefined

      if (!daysDifference || daysDifference > ELECTION_COMPLETED_MAX_DAYS) {
        return undefined
      }

      return {
        description: "Election completed",
        button: {
          label: "See results",
          url: ROUTES.governance.council.election.round2.index(
            organization.slug,
            council.slug,
            election.number,
          ),
        },
      }
    }

    return undefined
  }

  const bannerDetails = getBannerDetails()

  if (!bannerDetails) {
    return null
  }

  const { description, button } = bannerDetails

  return (
    <Stack
      bgColor="gray.900"
      borderRadius="lg"
      direction="row"
      px={2}
      py={4}
      spacing={4}
    >
      <Icon as={SecurityCouncil} fill="white" h={14} mt={1} w={14} />
      <Stack direction="column" spacing={0}>
        <Text
          color="white"
          display={onLargeDevice}
          fontSize="lg"
          fontWeight="bold"
        >
          Security Council Election
        </Text>
        <Text
          color="white"
          display={onLittleDevice}
          fontSize="lg"
          fontWeight="bold"
        >
          Security Council
        </Text>
        <Text color="gray.300" fontSize="sm">
          {description}
        </Text>
        {button ? (
          <Link
            color="white"
            fontSize="md"
            fontWeight="medium"
            href={button.url}
            mt={{ base: 1, md: 4 }}
          >
            <Stack align="center" direction="row">
              <Text>{button.label}</Text>
              <Icon as={ArrowRight} fill="white" h={4} w={4} />
            </Stack>
          </Link>
        ) : null}
      </Stack>
    </Stack>
  )
}

export default OrganizationSecurityCouncil
