import type { FC } from "react"
import { Stack } from "@chakra-ui/react"

import OrganizationGovernancePortal from "organization/components/announcement/OrganizationGovernancePortal"
import OrganizationDelegationPortal from "organization/components/announcement/OrganizationDelegationPortal"
import {
  OrganizationStatus,
  type OrganizationAnnouncement,
} from "organization/helpers/fetcher"
import OrganizationRegisterAsADelegate from "organization/components/announcement/OrganizationRegisterAsADelegate"
import type { Organization } from "query/graphql"
import ErrorBoundary from "common/components/ErrorBoundary"
import { useDevice } from "common/hooks/useDevice"
import OrganizationHelpfulLinks from "organization/components/OrganizationHelpfulLink"
import { spacing } from "theme/foundations/spacing"

type OrganizationPreGovernorLayoutProps = {
  organizationAnnouncement: OrganizationAnnouncement
  organization: Organization
}

const OrganizationPreGovernorLayout: FC<OrganizationPreGovernorLayoutProps> = ({
  organizationAnnouncement,
  organization,
}) => {
  const {
    registerAsADelegate,
    delegationPortal,
    governancePortal,
    orgStatus,
    externalLinks,
  } = organizationAnnouncement

  const { onLittleDevice, onLargeDevice } = useDevice()

  const displayContent = [
    OrganizationStatus.CallForDelegates,
    OrganizationStatus.ClaimLive,
  ].includes(orgStatus)

  if (!displayContent) return null

  return (
    <>
      <Stack
        align="flex-start"
        direction="row"
        display={onLargeDevice}
        spacing={spacing.containerGap}
      >
        <Stack direction="column" spacing={spacing.containerGap} width="70%">
          {delegationPortal ? (
            <ErrorBoundary section="OrganizationDelegationPortal">
              <OrganizationDelegationPortal
                content={delegationPortal}
                organization={organization}
                status={orgStatus}
              />
            </ErrorBoundary>
          ) : null}

          {governancePortal ? (
            <ErrorBoundary section="OrganizationGovernancePortal">
              <OrganizationGovernancePortal
                content={governancePortal}
                status={orgStatus}
              />
            </ErrorBoundary>
          ) : null}
        </Stack>

        <Stack direction="column" spacing={spacing.containerGap} width="30%">
          {registerAsADelegate ? (
            <ErrorBoundary section="OrganizationRegisterAsADelegate">
              <OrganizationRegisterAsADelegate
                content={registerAsADelegate}
                organization={organization}
              />
            </ErrorBoundary>
          ) : null}

          <ErrorBoundary section="OrganizationHelpfulLinks">
            <OrganizationHelpfulLinks
              displayOrgLinks={false}
              displayTitle={false}
              externalLinks={externalLinks}
              organization={organization}
            />
          </ErrorBoundary>
        </Stack>
      </Stack>

      <Stack display={onLittleDevice} spacing={spacing.containerGap}>
        {delegationPortal ? (
          <ErrorBoundary section="OrganizationDelegationPortal">
            <OrganizationDelegationPortal
              content={delegationPortal}
              organization={organization}
              status={orgStatus}
            />
          </ErrorBoundary>
        ) : null}

        {registerAsADelegate ? (
          <ErrorBoundary section="OrganizationRegisterAsADelegate">
            <OrganizationRegisterAsADelegate
              content={registerAsADelegate}
              organization={organization}
            />
          </ErrorBoundary>
        ) : null}

        {governancePortal ? (
          <ErrorBoundary section="OrganizationGovernancePortal">
            <OrganizationGovernancePortal
              content={governancePortal}
              status={orgStatus}
            />
          </ErrorBoundary>
        ) : null}

        <ErrorBoundary section="OrganizationHelpfulLinks">
          <OrganizationHelpfulLinks
            displayOrgLinks={false}
            externalLinks={externalLinks}
            organization={organization}
          />
        </ErrorBoundary>
      </Stack>
    </>
  )
}
export default OrganizationPreGovernorLayout
