import { Flex, Text, Stack } from "@chakra-ui/react"
import type { FC } from "react"
import truncate from "truncate"
import { isAddress } from "@ethersproject/address"

import Link from "common/components/Link"
import CopyButton from "common/components/CopyButton"
import UserAvatar from "common/components/UserAvatar"
import { ROUTES } from "common/constants/routes"
import type { Account, Organization } from "query/graphql"
import { shortString } from "common/helpers/string"

type Props = {
  account: Account
  organization: Organization
}
export const AccountIdentity: FC<Props> = ({ account, organization }) => {
  const { address, picture, name } = account

  const displayName = Boolean(name) ? name : address

  return (
    <Stack align="center" direction="row" spacing={3}>
      <Link
        href={ROUTES.governance.delegate.profile(organization.slug, address)}
      >
        <UserAvatar
          address={address}
          shouldShowBadge={false}
          size="md"
          src={picture}
        />
      </Link>
      <Flex direction="column">
        <Link
          href={ROUTES.governance.delegate.profile(organization.slug, address)}
        >
          <Text color="gray.800" fontSize="md" fontWeight="bold">
            {isAddress(displayName)
              ? shortString(displayName)
              : truncate(displayName, 20)}
          </Text>
        </Link>
        <Stack align="center" direction="row" mt="-4px">
          <Text color="gray.600" fontSize="sm" fontWeight="medium">
            {shortString(address)}
          </Text>
          <CopyButton size={8} tooltipLabel="address" value={address} />
        </Stack>
      </Flex>
    </Stack>
  )
}
