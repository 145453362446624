import { EXTERNAL_ENDPOINTS } from "common/constants/endpoints"
import { fetcher, GrpcErrorType } from "common/helpers/fetcher"
import {
  GovernorsByOrganizationDocument,
  GovernorsSortBy,
  GovernorType,
  OrganizationBySlugDocument,
  OrganizationFeaturesDocument,
  SignalVoteServicesDocument,
} from "query/graphql"
import type {
  GovernorsByOrganizationQuery,
  GovernorsByOrganizationQueryVariables,
  Governor,
  OrganizationBySlugQuery,
  OrganizationBySlugQueryVariables,
  OrganizationFeaturesQuery,
  OrganizationFeaturesQueryVariables,
  SignalVoteService,
  SignalVoteServicesQuery,
  SignalVoteServicesQueryVariables,
} from "query/graphql"

export const fetchOrganizationBySlug = async (
  slug: string,
): Promise<OrganizationBySlugQuery["organization"] | null> => {
  try {
    const metadata = await fetcher.gql<
      OrganizationBySlugQuery,
      OrganizationBySlugQueryVariables
    >({
      query: OrganizationBySlugDocument,
      variables: {
        input: {
          slug,
        },
      },
      omittedErrors: [GrpcErrorType.NotFound],
    })

    if (metadata) {
      const organization = {
        ...metadata.organization,
      }

      if (isForcePreGovernor(organization.id)) {
        return { ...organization, governorIds: [] }
      }

      return Promise.resolve(organization)
    }

    return Promise.resolve(null)
  } catch {
    return Promise.resolve(null)
  }
}

export const fetchGovernorsByOrganization = async (
  organizationId: string,
): Promise<Governor[]> => {
  if (isForcePreGovernor(organizationId)) {
    return []
  }

  try {
    const response = await fetcher.gql<
      GovernorsByOrganizationQuery,
      GovernorsByOrganizationQueryVariables
    >({
      query: GovernorsByOrganizationDocument,
      variables: {
        input: {
          filters: {
            organizationId,
          },
          sort: {
            sortBy: GovernorsSortBy.Id,
            isDescending: true,
          },
        },
      },
      omittedErrors: [GrpcErrorType.NotFound],
    })

    // Sort Governors by type (isPrimary first, then Hub, then others)
    const byType = (
      a: Pick<Governor, "type" | "isPrimary">,
      b: Pick<Governor, "type" | "isPrimary">,
    ) => {
      if (a.isPrimary) return -1
      if (b.isPrimary) return 1
      if (a.type === GovernorType.Hub) return -1
      if (b.type === GovernorType.Hub) return 1

      return 1
    }

    if (response) {
      const governors = (
        response.governors?.nodes.filter((governor) =>
          Boolean(governor),
        ) as Governor[]
      ).sort(byType)

      return Promise.resolve(governors)
    }

    return Promise.resolve([])
  } catch {
    return Promise.resolve([])
  }
}

export const fetchOrganizationFeatures = async (
  organizationId: string,
): Promise<OrganizationFeaturesQuery["organization"] | null> => {
  try {
    const metadata = await fetcher.gql<
      OrganizationFeaturesQuery,
      OrganizationFeaturesQueryVariables
    >({
      query: OrganizationFeaturesDocument,
      variables: {
        input: {
          id: organizationId,
        },
      },
    })

    if (metadata) {
      return Promise.resolve(metadata.organization)
    }

    return Promise.resolve(null)
  } catch (err) {
    return Promise.resolve(null)
  }
}

export const fetchSignalVoteServices = async (
  organizationId: string,
): Promise<Pick<SignalVoteService, "id">[]> => {
  try {
    const response = await fetcher.gql<
      SignalVoteServicesQuery,
      SignalVoteServicesQueryVariables
    >({
      query: SignalVoteServicesDocument,
      variables: {
        input: {
          organizationId,
        },
      },
    })

    if (response) {
      return Promise.resolve(response.signalVoteServices)
    }

    return Promise.resolve([])
  } catch {
    return Promise.resolve([])
  }
}

/**
 * Temporary function to force displaying a Pre-governor layout
 * although the organization has governors
 */
const isForcePreGovernor = (organizationId: string) => {
  const FORCE_PRE_GOV_ORG_IDS = [
    // "2413388957975839812", // /gov/obol
    "-",
  ]

  return FORCE_PRE_GOV_ORG_IDS.includes(organizationId)
}

export enum OrganizationStatus {
  CallForDelegates = "CallForDelegates",
  ClaimLive = "ClaimLive",
  GovernanceLive = "GovernanceLive",
}

export type ExternalLink = {
  label: string
  url: string
  isExternal: boolean
}

type Banner = {
  title?: string
  description?: string
  textColor?: { css: string }
  bigBackgroundImage?: {
    fileName: string
    url: string
    height: number
    width: number
  }
  smallBackgroundImage?: {
    fileName: string
    url: string
    height: number
    width: number
  }
  externalLink?: ExternalLink
}

export type OrganizationAnnouncement = {
  orgStatus: OrganizationStatus
  externalLinks?: ExternalLink[]
  registerAsADelegate?: string
  registerAsDelegateOptional?: string
  activeDelegateCheckboxSubtitle?: string
  delegationPortal?: string
  governancePortal?: string
  delegatesInfoCard?: string
  bigBannerImageFile?: {
    fileName: string
    url: string
    height: number
    width: number
  }
  smallBannerImageFile?: {
    fileName: string
    url: string
    height: number
    width: number
  }
  banner?: Banner
}

type OrganizationAnnouncementResponse = {
  organizationAnnouncement: OrganizationAnnouncement | undefined
}

export const fetchOrganizationAnnouncement = async ({
  organizationId,
}: {
  organizationId: string
}): Promise<OrganizationAnnouncement | null> => {
  try {
    const query = `
      query {
        organizationAnnouncement(where: {organizationId: "${organizationId}"}) {
          orgStatus
          externalLinks {
            label
            url
            isExternal
          }
          registerAsADelegate
          delegationPortal
          governancePortal
          delegatesInfoCard
          registerAsDelegateOptional
          activeDelegateCheckboxSubtitle
          smallBannerImageFile {
            fileName
            height
            width
            url
          }
          bigBannerImageFile {
            fileName
            height
            width
            url
          }
          banner {
            ... on Banner {
              title
              description
              textColor {
                css
              }
              bigBackgroundImage {
                fileName
                height
                width
                url
              }
              externalLink {
                label
                url
                isExternal
              }
            }
          }
        }
      }`
    const endpoint = EXTERNAL_ENDPOINTS.graphCms()
    const response = await fetcher.gql<
      OrganizationAnnouncementResponse | undefined
    >({
      query,
      endpoint,
      onError: (error) => console.log(error),
    })

    const organizationAnnouncement = response?.organizationAnnouncement ?? null

    return organizationAnnouncement
  } catch (err) {
    return null
  }
}
